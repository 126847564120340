* {
    margin: 0;
    padding: 0;
    font-family: "Outfit", sans-serif;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
    background-color: #f3f3f3 !important;
    font-family: "Outfit", sans-serif !important;
    overflow-y: auto;
}


/* .ck-editor__editable img {
    display: none !important;
} */


/* Main container styles */

.main_content {
    min-height: 100vh;
    /* Ensure it covers the full viewport height */
    padding: 1rem;
    /* Add some padding */
    overflow-y: auto;
    /* Enable vertical scrolling for content */
}

.css-1v5u2yv {
    padding: 0.5rem 0rem 0rem 0rem !important;
    padding-top: 0 !important;
}

.css-zxtyt4-MuiPaper-root-MuiAppBar-root {
    background-color: #210720 !important;
    /* height: 76px; */
}

.ck-editor {
    color: black;
}

.col_custom {
    background-color: #f3f3f3;
}

.main_box_dashboard {
    display: none !important;
}


/* scroll table */

.table-responsive {
    overflow-x: clip !important;
    max-height: 500px;
    overflow-y: auto;
}

.table-responsive::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
}

.table-responsive::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: #d9d9d9;
}


/* end scroll table */

.scrollableTable {
    max-height: 500px;
    overflow-y: auto;
}

.scrollableTable::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
}

.scrollableTable::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: #d9d9d9;
}

.table_middle_text_box {
    transform: translate(0px, 15px);
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.seo-note-box {
    margin-top: 1rem;
}

.seo-note-box .seo-note-title {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    margin-bottom: 0;
    font-weight: 500;
}

.css-yb0lig {
    font-family: "Outfit", sans-serif !important;
    font-size: 0.9rem !important;
}

.seo-note-box .seo-note-title span {
    font-weight: 600;
}

.h_pop_up_img_box {
    height: 300px;
    width: 100%;
    margin-top: 2rem;
}

.h_pop_up_img_box img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.home_pop_up_form_g {
    border: 1px solid #d9d9d973;
    padding: 1rem;
}

.h_pop_up_main_title {
    font-size: 17px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.blog_desc .ck.ck-editor {
    position: relative;
    width: 100% !important;
}

.NoteBox {
    margin-top: 1.5rem;
    text-align: center;
}

.NoteBox p {
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.NoteBox p span {
    color: red;
}


/* Target the MuiBox with specific generated class */

.MuiBox-root.css-1v5u2yv {
    overflow-y: auto !important;
    /* Enable vertical scrolling */
    height: 100% !important;
    /* Ensure it takes the full height of the viewport */
}


/* Customize the scrollbar */

.MuiBox-root.css-1v5u2yv::-webkit-scrollbar {
    width: 10px !important;
    background-color: hsl(0deg 0% 94.51%) !important;
}

.MuiBox-root.css-1v5u2yv::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5) !important;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
}


/* media query */

@media (max-width:1024px) {
    .table-responsive {
        overflow-x: scroll !important;
    }
    .add_products_heading {
        width: 100% !important;
    }
}